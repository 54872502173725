<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="companiesCamerasTable"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="custom-data-table"
        dense
        must-sort
        :loading="loading"
        loading-text="Loading..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        :server-items-length="total"
        item-key="id"
        fixed-header
        :footer-props="{
          'items-per-page-options': [50, 100, 500],
        }"
        :hide-default-footer="items.length <= 50"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="pl-0 pb-0 text-h6"> Cameras </ECol>
              <v-spacer />
              <ECol cols="1" class="mb-1 pb-0 text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item.name="{ item }">
          <span
            class="cursor-pointer primary--text"
            @click="openCameraDialog(item)"
          >
            {{ item.name }}
          </span>
          <ImpersonateUserButton
            :email="item.ownerEmail"
            :target-route="`/projects/${item.projectExid}/${item.exid}`"
          />
        </template>
        <template #item.status="{ item }">
          <span :class="getStatusColor(item.status)">
            {{ getStatusText(item.status) }}
          </span>
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import headers from "@/components/companies/companiesCamerasTableHeaders"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import CameraUtils from "@/mixins/cameraUtils"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { decamelize } from "humps"

export default {
  components: {
    CopyTableToClipboard,
    ImpersonateUserButton,
  },
  mixins: [CameraUtils],
  props: {
    companyId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      items: [],
      headers,
      total: 0,
      loading: false,
      options: {},
      sortBy: "name",
      sortDesc: false,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.companiesCamerasTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    companyId() {
      this.fetchCameras()
    },
    options: {
      handler() {
        this.fetchCameras()
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchCameras()
  },
  methods: {
    async fetchCameras() {
      if (!this.companyId) {
        return
      }
      try {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage: limit } = this.options
        const sort = `${decamelize(sortBy?.[0] || "")}|${this.whichSort(
          sortDesc?.[0]
        )}`
        const params = { sort, page, limit }
        const { total, items } = await EvercamApi.companies.getCompanyCameras(
          this.companyId,
          { params }
        )
        this.total = total
        this.items = items
      } catch (error) {
        this.$notifications.error({ text: "Could not load Cameras!", error })
      } finally {
        this.loading = false
      }
    },
    whichSort(type) {
      return type ? "desc" : "asc"
    },
    openCameraDialog(camera) {
      useCameraDialogStore().openDialog({ camera: camera.exid })
    },
  },
}
</script>
